import axios from 'axios'
import {
	Message
} from 'element-ui'
import router from '@/router/index'
// import md5 from 'js-md5'

function setCookie() {
	// document.cookie = 'access-token=9a242c55888e46d7ad714c19bd624783'
}
export default {

	get(url, params) {
		// var curUrl = window.location.href
		url = '/server' + url
		setCookie()
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'get',
					url,
					params
				}).then(data => {
					console.log()
					if (data.status == 200) {
						if (data.data.code) {
							if (data.data.code == 200) {
								res(data.data.result)
							} else if (data.data.code == 401) {
								Message.closeAll()
                router.push(`/login`).catch(() => {})
								// if(process.env.NODE_ENV == 'development') {
								// window.location.href = devHref + '/login?url=' + devHref
								// } else if(process.env.NODE_ENV == 'production') {
								// window.location.href = productionHref + '/login?url=' + productionHref
								// }
								// window.location.href =
								// 	'http://openepyun.whaleng.com/login?url=' + encodeURIComponent(curUrl)
								// window.location.href = 'http://127.0.0.1:3000/login?url=http://127.0.0.1:8080'
							} else if (data.data.code == 403) {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '没有权限'
								})
							} else {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请求失败'
								})
								rej(data.data.result)
							}
						} else {
							Message.closeAll()
							res(data.data)
						}
					} else {
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	getFile(url) {
		setCookie()
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'get',
					url: url,
					responseType: 'blob' // 注意返回的数据格式
				}).then(data => {
					if (data.status == 200) {
						res(data.data)
					} else {
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	post(url, data, headers = {}) {
		// var curUrl = window.location.href
		url = '/server' + url
		setCookie()
		if (typeof headers !== 'object') {
			headers = {}
		}
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'post',
					url,
					data,
					headers: headers
				}).then(data => {
					// Loading.service({ fullscreen: true }).close()
					if (data.status == 200) {
						if (data.data.code) {
							if (data.data.code == 200) {
								res(data.data.result)
							} else if (data.data.code == 401) {
								Message.closeAll()
                router.push(`/login`).catch(() => {})
								// if(process.env.NODE_ENV == 'development') {
								// window.location.href = devHref + '/login?url=' + devHref
								// } else if(process.env.NODE_ENV == 'production') {
								// window.location.href = productionHref + '/login?url=' + productionHref
								// }
								// window.location.href =
								// 	'http://openepyun.whaleng.com/login?url=' + encodeURIComponent(curUrl)
								// window.location.href = 'http://127.0.0.1:3000/login?url=http://127.0.0.1:8080'
							} else if (data.data.code == 403) {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '没有权限'
								})
							} else {
								Message.closeAll()
								rej(data.data)
								Message({
									type: 'error',
									message: data.data.msg || '请求失败'
								})
								
							}
						} else {
							res(data.data)
						}
					} else {
						Message.closeAll()
						Message({
							type: 'error',
							message: '请求异常'
						})
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	//文件下载
	download(url, data, headers = {}) {
		url = 'server' + url
		setCookie()
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'post',
					url,
					data,
					headers,
					responseType: 'blob'
				}).then(res => {
					console.log(res)
					if (res.status == 200) {
						if (res.data) {
							var blob = new Blob([res.data])
							var url = window.URL.createObjectURL(blob)
							var aLink = document.createElement('a')
							aLink.style.display = 'none'
							aLink.href = url
							let contentDisposition = res.headers['content-disposition'] //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
							let patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
							let result = patt.exec(contentDisposition)
							let filename = decodeURI(result[1])
							aLink.setAttribute('download', filename)
							document.body.appendChild(aLink)
							aLink.click()
							document.body.removeChild(aLink) //下载完成移除元素
							window.URL.revokeObjectURL(url) //释放掉blob对象
						} else {
							Message({
								type: 'error',
								message: '请求异常'
							})
							rej(data)
						}
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	}
}