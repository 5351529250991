
// 时间日期 把时间戳还原成年月日等时间格式
export function parseTime(time, cFormat) {
  // console.log('parseTime___-', time)
  if (arguments.length === 0) {
    return null
  }
  // console.log('parseTime___-1')
  if (time === undefined) {
    return null
  }
  // console.log('parseTime___-2')
  const format = cFormat || '{y}-{m}-{d}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    // console.log(time)
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    o: date.getMonth(),
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a|o)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (key === 'o') {
      return ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'][
        value
      ]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  // console.log(time_str)
  return time_str
}

export function mapLabel(value, key) {
  const data = JSON.parse(localStorage.getItem('enumList'))
  if (key && data[key]) {
    return (
      data[key].find(e => e.value === value) || {
        label: value
      }
    ).label
  } else return ''
}

export function generateUUID() {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var uuid = '';
 
  for (var i = 0; i < 16; i++) {
    var randomIndex = Math.floor(Math.random() * chars.length);
    uuid += chars[randomIndex];
  }
 
  return uuid;
}



export function setRemBaseFontSize() {  
  const designWidth = 375; // 设计稿基准宽度  
  const remRatio = 100 / designWidth; // 根据设计稿宽度设置比例，比如设计稿是375px宽，这里就是100/375  
  const fontSize = window.innerWidth * remRatio; // 根据屏幕宽度计算font-size  
  document.documentElement.style.fontSize = fontSize + 'px';
}