import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'

import '@/utils/request'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css'
Vue.use(Element)
import { parseTime, mapLabel } from '@/utils/common'

Vue.filter('formatTime', parseTime)
Vue.filter('mapLabel', mapLabel)

// Vue.prototype.$wsUrl = 'ws://192.168.0.109:29028/websock/ws'
Vue.prototype.$wsUrl = 'ws://kefu.whaleng.com/websock/ws'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
