import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/im',
    name: 'Im',
    component: () => import('../views/im.vue')
  },
  {
    path: '/customerServiceIm',
    name: 'CustomerServiceIm',
    component: () => import('../views/customerServiceIm.vue')
  },
  {
    path: '/reply',
    name: 'Reply',
    component: () => import('../views/reply.vue')
  },
  {
    path: '/leaveMessage',
    name: 'LeaveMessage',
    component: () => import('../views/leaveMessage.vue')
  },
  {
    path: '/phoneServiceIm',
    name: 'PhoneServiceIm',
    component: () => import('../views/phoneServiceIm.vue')
  },
  {
    path: '/waitList',
    name: 'WaitList',
    component: () => import('../views/waitList.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
